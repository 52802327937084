import { type Provider, createContext, useContext } from "react";

export const createCtx = <T>(
  initial?: T
): [() => T, Provider<T | undefined>] => {
  const ctx = createContext<T | undefined>(initial);

  const useCtx = () => {
    const context = useContext<T | undefined>(ctx);

    if (context === undefined) {
      throw new Error("useCtx must be inside a Provider with a value");
    }

    return context;
  };

  return [useCtx, ctx.Provider];
};
