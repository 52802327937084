import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { checkFullUsername, checkPass, checkUsername } from "util/validators";

import { Provider } from "components/organisms/login/provider";
import usePersistedState from "hooks/persisted_state/usePersistedState";
import { useSlowQueryStateWithNavigationChange } from "hooks/query/useSlowQueryStateWithNavigationChange";
import { TOKEN_PERSIST_KEY } from "util/const";

interface Props {
  children: ReactNode;
}

export interface LoginContext {
  formOk: boolean;
  username?: string;
  usernameWithDomain?: string;
  pass?: string;
  locked: boolean;
  keepLogged: boolean;
  type: LoginType;
  setType: (type: LoginType) => void;
  setUsernameWithDomain: Dispatch<SetStateAction<string | undefined>>;
  setPass: Dispatch<SetStateAction<string | undefined>>;
  setLocked: Dispatch<SetStateAction<boolean>>;
  setKeepLogged: Dispatch<SetStateAction<boolean>>;
  ldap: {
    domain?: string;
  };
}
export type LoginType = "ldap" | "internal" | "azure";

const isLoginType = (type: string): type is LoginType =>
  ["ldap", "internal", "azure"].includes(type);

const LoginProvider = ({ children }: Props): JSX.Element => {
  const [usernameWithDomain, setUsernameWithDomain] = usePersistedState<string | undefined>(
    "username",
  );

  const [pass, setPass] = useState<string | undefined>();
  const [locked, setLocked] = usePersistedState<boolean>("lock", false);

  const [keepLogged, setKeepLogged] = usePersistedState<boolean>(
    TOKEN_PERSIST_KEY,
    true,
    localStorage,
    "",
  );

  const [_type, _setType] = usePersistedState<LoginType>("loginType", "internal", localStorage, ""); // remember the state when entering login without loginType

  const [query, setQuery] = useSlowQueryStateWithNavigationChange<{
    loginType?: string;
  }>();

  const type = useMemo<LoginType>(() => {
    const type = query.loginType?.toString();

    if (type && isLoginType(type)) {
      return type;
    }

    return _type;
  }, [_type, query.loginType]);

  const setType = useCallback(
    (type: LoginType) => {
      setQuery(o => ({ ...o, loginType: type }));
      _setType(type);
    },
    [_setType, setQuery],
  );

  const [username = "", domain = ""] = usernameWithDomain?.split("@") ?? [];

  const formOk = useMemo(() => {
    const ok = !locked && !!username && !checkFullUsername(usernameWithDomain);

    if (type === "azure") {
      return true;
    }
    if (type === "ldap") {
      return ok && !!domain && !checkPass(pass) && !checkUsername(username);
    }
    return ok && !!pass && !checkPass(pass);
  }, [domain, locked, pass, type, username, usernameWithDomain]);

  const value = useMemo<LoginContext>(
    () => ({
      formOk,
      username,
      usernameWithDomain,
      pass,
      locked,
      keepLogged,
      type,
      setType,
      setUsernameWithDomain,
      setPass,
      setLocked,
      setKeepLogged,
      ldap: {
        domain,
      },
    }),
    [
      formOk,
      username,
      usernameWithDomain,
      pass,
      locked,
      keepLogged,
      type,
      setType,
      setUsernameWithDomain,
      setLocked,
      setKeepLogged,
      domain,
    ],
  );

  return <Provider value={value}>{children}</Provider>;
};

export default LoginProvider;
