import { Suspense, lazy } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import SlugProvider from "SlugProvider";
import ModuleLoader from "components/atoms/module_loader/ModuleLoader";
import ConfirmationProvider from "components/hoc/confirmation/ConfirmationProvider";
import MercureListener from "components/hoc/mercure_listener/MercureListener";
import NotificationProvider from "components/hoc/notifications/NotificationProvider";
import QueryStateContextProvider from "components/hoc/query/QueryStateContextProvider";
import QuickLoginProvider from "components/hoc/quick_login/QuickLoginProvider";
import SnackMessageProvider from "components/hoc/snack_message/SnackMessageProvider";
import { useUserCtx } from "components/hoc/user/provider";
import Notifications from "components/molecules/notifications/Notifications";
import ChangePasswordForm from "components/organisms/change_password/ChangePasswordForm";
import ChangePasswordProvider from "components/organisms/change_password/ChangePasswordProvider";
import ForgotForm from "components/organisms/forgot/ForgotForm";
import ForgotProvider from "components/organisms/forgot/ForgotProvider";
import CompanyChooser from "components/organisms/login/CompanyChooser";
import LoginProvider from "components/organisms/login/LoginProvider";
import ResetForm from "components/organisms/reset/ResetForm";
import ResetProvider from "components/organisms/reset/ResetProvider";
import ForgotInfo from "components/pages/forgot_info/ForgotInfo";
import Authorized from "components/router/Authorized";
import ErrorCmp from "components/templates/error/error/Error";
import ErrorBoundary from "components/templates/error/error_boundary/ErrorBoundary";
import LoginLoader from "components/templates/loaders/LoginLoader";
import SessionExpiredException from "exceptions/session_expired";
import { useSlowQueryStateWithNavigationChange } from "hooks/query/useSlowQueryStateWithNavigationChange";
import { HOME_PAGE } from "util/const";

const Login = lazy(() => import("../pages/login/Login"));
const Logout = lazy(() => import("../pages/logout/Logout"));
const NoMatch = lazy(() => import("../pages/no_match/NoMatch"));
const AppRoutes = lazy(() => import("./AppRoutes"));

const SlugRedirect = (): JSX.Element => {
  const { user } = useUserCtx();

  if (user) {
    return <Navigate to={`/${import.meta.env.VITE_FORCE_SLUG || user.companySlug}`} replace />;
  }

  return <Navigate to="/login" />;
};

const AzureRedirect = (): JSX.Element => {
  const [{ code, state, session_state }] = useSlowQueryStateWithNavigationChange<{
    code?: string;
    state?: string;
    session_state?: string;
  }>();

  const params = {
    code: code?.toString() ?? "",
    state: state?.toString() ?? "",
    session_state: session_state?.toString() ?? "",
    login_type: "azure",
  };

  return (
    <Navigate
      to={{
        pathname: "/login",
        search: new URLSearchParams(params).toString(),
      }}
    />
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <QueryStateContextProvider>
          <NotificationProvider>
            <SnackMessageProvider>
              <ConfirmationProvider>
                <Outlet />
                <Notifications />
              </ConfirmationProvider>
            </SnackMessageProvider>
          </NotificationProvider>
        </QueryStateContextProvider>
      }
    >
      <Route path="azure-connect" element={<AzureRedirect />} />

      <Route
        path="login"
        element={
          import.meta.env.VITE_FORCE_SLUG ? (
            <Navigate to={`${import.meta.env.VITE_FORCE_SLUG}/login`} />
          ) : (
            <Suspense fallback={<LoginLoader />}>
              <CompanyChooser />
            </Suspense>
          )
        }
      />

      <Route
        path=":slug/*"
        element={
          <SlugProvider>
            <ErrorBoundary
              getJsxForError={error => {
                if (error instanceof SessionExpiredException) {
                  return <Navigate to="login" />;
                }

                return (
                  <div className="flex h-full w-full">
                    <ErrorCmp
                      className="m-auto rounded bg-surface p-3"
                      cause={t`errors.unexpected_cause|An unexpected error occurred`}
                      error={error}
                      onClick={() => window.location.reload()}
                    />
                  </div>
                );
              }}
            >
              <Routes>
                <Route
                  path="logout"
                  element={
                    <Suspense
                      fallback={<ModuleLoader message={t`labels.loading_logout_page|Loading...`} />}
                    >
                      <Logout />
                    </Suspense>
                  }
                />
                <Route
                  path="login"
                  element={
                    <Suspense fallback={<LoginLoader />}>
                      <LoginProvider>
                        <Login />
                      </LoginProvider>
                    </Suspense>
                  }
                />

                <Route
                  path="forgot"
                  element={
                    <Suspense fallback={<LoginLoader />}>
                      <ForgotProvider>
                        <ForgotForm />
                      </ForgotProvider>
                    </Suspense>
                  }
                />

                <Route
                  path="forgot_info"
                  element={
                    <Suspense
                      fallback={
                        <ModuleLoader message={t`labels.loading_forgot_info_page|Loading...`} />
                      }
                    >
                      <ForgotInfo />
                    </Suspense>
                  }
                />

                <Route
                  path="user/password-reset"
                  element={
                    <Suspense fallback={<LoginLoader />}>
                      <ResetProvider>
                        <ResetForm />
                      </ResetProvider>
                    </Suspense>
                  }
                />

                <Route
                  path="user/change-password"
                  element={
                    <Suspense fallback={<LoginLoader />}>
                      <ChangePasswordProvider>
                        <ChangePasswordForm />
                      </ChangePasswordProvider>
                    </Suspense>
                  }
                />

                <Route
                  index
                  path="*"
                  element={
                    <Authorized>
                      <QuickLoginProvider>
                        <MercureListener>
                          <Suspense
                            fallback={
                              <ModuleLoader message={t`labels.loading_page|Loading page...`} />
                            }
                          >
                            <AppRoutes />
                          </Suspense>
                        </MercureListener>
                      </QuickLoginProvider>
                    </Authorized>
                  }
                />
              </Routes>
            </ErrorBoundary>
          </SlugProvider>
        }
      />

      <Route index element={<SlugRedirect />} />

      <Route
        path="*"
        element={
          <Suspense
            fallback={<ModuleLoader message={t`labels.loading_no_match_page|Loading...`} />}
          >
            <NoMatch />
          </Suspense>
        }
      />
    </Route>,
  ),
  {
    basename: HOME_PAGE,
  },
);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}
