import { getProtectedApi } from "api/common";
import { type Abortable, request } from "api/utils";
import type Catalog from "model/catalog/Catalog";
import type { CatalogChild } from "model/catalog/Catalog";
import type { PaginationState, Sorting } from "model/util";
import { BASE_API_URL } from "util/const";

interface GetCatalogParams extends PaginationState {
  id: string;
  namespace: string;
  sorting?: Sorting;
}

interface GetCatalogContentsParams {
  namespace: string;
  ids: string[];
}

export const getCatalog = ({
  namespace,
  id,
}: Omit<GetCatalogParams, "sorting">) =>
  getProtectedApi()
    .get<Catalog>(`/${namespace}/${id}/load`)
    .then((r) => r.data);
export const getCatalogContents = <T extends CatalogChild = CatalogChild>({
  namespace,
  ...params
}: GetCatalogContentsParams) =>
  getProtectedApi()
    .post<Record<string, T>>(`/${namespace}/list`, params)
    .then((r) => r.data);

export const createCatalog = ({
  namespace,
  parent,
  name,
}: {
  namespace: string;
  parent: string;
  name: string;
}) =>
  getProtectedApi()
    .post<Catalog>(`/${namespace}/${parent}/create`, {
      name,
      nodeType: "catalog",
      type: "catalog",
    })
    .then((r) => r.data);
export const updateCatalog = ({
  namespace,
  id,
  name,
}: {
  namespace: string;
  id: string;
  name: string;
}) =>
  getProtectedApi()
    .post<Catalog>(`/${namespace}/${id}/update`, {
      id,
      name,
      nodeType: "catalog",
      type: "catalog",
    })
    .then((r) => r.data);
export const deleteMany = (ns: string, ids: string[]): Abortable =>
  request(
    ids.length === 1
      ? `${BASE_API_URL}/${ns}/${ids[0]}/delete`
      : `${BASE_API_URL}/${ns}/multi-delete`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: { ids },
    }
  );
export const pasteToCatalog = (params: {
  ns: string;
  catalogId: string;
  items: string[];
  move: boolean;
}) =>
  getProtectedApi()
    .put<void>(`${params.ns}/${params.catalogId}/move`, {
      items: params.items,
      copy: !params.move,
    })
    .then((r) => r.data);
