import type { Option } from "model/util";
import { createCtx } from "util/context";

const isOption = (
  param: string | number | boolean | Option<string> | unknown,
): param is Option<string> => {
  return typeof param === "object" && param !== null && "id" in param;
};

const getHex = async (value: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string

  return hashHex.slice(0, 6).toString();
};

export const formatParam = async (
  param: string | number | boolean | Option<string> | unknown,
): Promise<string> => {
  if (isOption(param)) {
    return await getHex(param.name);
  }

  if (typeof param === "undefined") {
    return "";
  }

  if (typeof param === "object") {
    return "";
  }

  if (!param) {
    return "";
  }

  return `${await getHex(param?.toString() ?? "")}`;
};

interface DataWithVariants extends Record<string, unknown> {
  variants?: Record<string, Record<string, unknown>>;
}

export interface DataSourceData {
  [key: string]: DataWithVariants;
}

export const [useLayoutSources, Provider] = createCtx<{
  isFetching: boolean;
  sources: DataSourceData;
}>();
