import { Navigate, useParams } from "react-router-dom";

import { isSchemeTheme, useThemeState } from "@panel2/systail-ui";
import { useUserCtx } from "components/hoc/user/provider";
import { SlugProvider, useSlug } from "provider";
import { type ReactNode, useEffect } from "react";
import { HOME_PAGE } from "util/const";

interface Props {
  children: ReactNode;
}

const replaceSlug = (slug: string, newSlug: string, location: Location): string => {
  let pathname = location.pathname;
  // get last url, replace (first component after domain) with companySlug
  let hp = HOME_PAGE || "/";

  if (hp.includes(".")) {
    // home page like ./ or .
    hp = hp.replace(".", "");
  }

  // if hp is empty this will make it /
  if (!hp.startsWith("/")) {
    hp = `/${hp}`;
  }

  // if hp was empty, it was converted to /, so this will remove it back to empty
  if (hp.endsWith("/")) {
    hp = hp.slice(0, -1);
  }

  if (hp) {
    const escapedHp = hp.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

    pathname = pathname.replace(new RegExp(`^${escapedHp}`), "");
  }

  // Create the regex to match the beginning of the pathname
  const escapedSlug = slug.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

  const regex = new RegExp(`^/${escapedSlug}`);
  const newPathname = pathname.replace(regex, `/${newSlug}`);
  const newLocation = `${newPathname}${location.search}${location.hash}`;

  return newLocation;
};

const SlugThemeManager = (): null => {
  const [, setTheme] = useThemeState();
  const slug = useSlug();

  useEffect(() => {
    const slugThemeDictStr = import.meta.env.VITE_SLUG_TO_SCHEME_DICT;

    if (slugThemeDictStr) {
      try {
        const slugThemeDict = JSON.parse(slugThemeDictStr);

        if (
          typeof slugThemeDict === "object" &&
          Object.values(slugThemeDict).every(v => !!v && typeof v === "object")
        ) {
          console.log("Setting theme", slugThemeDict[slug]);
          if (isSchemeTheme(slugThemeDict[slug])) {
            setTheme(slugThemeDict[slug]);
          }
        }
      } catch {
        console.error(
          "Failed to parse VITE_SLUG_TO_SCHEME_DICT, expected a JSON object with string keys and theme or scheme objects, found:",
          slugThemeDictStr,
        );
      }
    }
  }, [slug, setTheme]);
  return null;
};

const SlugProviderCmp = ({ children }: Props): JSX.Element => {
  // get slug from /:slug url
  const { slug } = useParams<{ slug: string }>();
  const { user, logout } = useUserCtx();

  if (!slug || slug === "login" || slug === "logout") {
    return <Navigate to="/login" />;
  }

  if (import.meta.env.VITE_FORCE_SLUG) {
    if (slug !== import.meta.env.VITE_FORCE_SLUG) {
      return (
        <Navigate
          to={replaceSlug(slug, import.meta.env.VITE_FORCE_SLUG, window.location)}
          replace
        />
      );
    }
  } else if (user) {
    const [token] = user.tokens;

    // parse token and validate slug
    if (token) {
      const parsedToken = JSON.parse(atob(token.split(".")[1]));

      if (!parsedToken.companySlug) {
        console.error(new Error("Token does not contain companySlug"));

        logout();

        return <></>;
      }

      if (parsedToken.companySlug !== slug) {
        const to = replaceSlug(slug, parsedToken.companySlug, window.location);

        return <Navigate to={to} replace />;
      }
    }
  }

  return (
    <SlugProvider value={slug}>
      <SlugThemeManager />
      {children}
    </SlugProvider>
  );
};

export default SlugProviderCmp;
